<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card-code title="Liste des user">
          <!-- input search -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Recherche</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Recherche"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="webUsercolumns"
            :rows="webUsers"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="props.row.status===0"
                      @click="toggleAcount(props.row)"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Activer le compte</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-else-if="props.row.status===1"
                      @click="toggleAcount(props.row)"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Desactiver le compte</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="editAccount(props.row)">
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Changer le role</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>

              <span v-else-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status==1?'Activer':'Desactiver' }}
                </b-badge>
              </span>
              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span></template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10','20','50']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
          <!--    Modal Edit-->
          <b-modal
            id="modal-edit-account"
            cancel-variant="outline-secondary"
            ok-title="Enregistrer"
            cancel-title="Close"
            centered
            size="lg"
            title="Modifier le role"
            @ok="updateAccount"
          >
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      label="Role"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Role"
                        vid="role"
                        rules="required"
                      >
                        <b-form-select
                          v-model="newRole"
                          :options="roles"
                          :state="errors.length > 0 ? false:null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
        </b-card-code>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BForm, BBadge, BModal, BFormSelect, BRow, BCol, BPagination, BFormGroup, BFormInput, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import webUserMixin from '../@core/mixins/webUserMixin'
import axios from '../libs/axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BRow,
    BCol,
    BModal,
    BForm,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  mixins: [webUserMixin],
  data() {
    return {
      required,
      pageLength: 10,
      dir: false,
      webUser: null,
      newRole: null,
      roles: [
        { value: null, text: 'Veuillez sélectionner une option' },
        { value: 'ADMIN', text: 'Administrateur' },
        { value: 'ANPC', text: "L'ANPC" },
        { value: 'POINTFOCAL', text: 'Un point focal' },
        { value: 'USER', text: 'Utilisateur Simple' },
      ],
      webUsercolumns: [
        {
          label: 'Nom',
          field: 'lastName',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Nom',
          },
        },
        {
          label: 'Prenom',
          field: 'firstName',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Prénom',
          },
        },
        {
          label: 'Télephone',
          field: 'phone',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par telephone',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par email',
          },
        },
        {
          label: 'Zone',
          field: 'zone.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par zone',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par status',
          },
        },
        {
          label: 'role',
          field: 'role',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par role',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {

  },
  methods: {
    getImage(link) {
      // eslint-disable-next-line no-undef
      return `${axios.defaults.baseURL}/${link}`
    },
    editAccount(webUser) {
      this.webUser = webUser
      this.$bvModal.show('modal-edit-account')
    },
    toggleAcount(webUser) {
      const user = webUser
      if (user.status === 0) {
        user.status = 1
      } else {
        user.status = 0
      }
      this.$store.dispatch('mainAppStore/updateWebUserItem', user).then(response => {
        console.log(response.data)
        this.$bvModal.hide('modal-edit-account')
        this.$swal({
          title: 'Succes',
          html: 'modifié avec succes',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        })
      })
        .catch(err => {
          this.$swal({
            title: 'Erreur',
            html: err.response.data.message,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          })
          console.error(err.response.headers)
        })
    },
    updateAccount(e) {
      e.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.webUser.role = this.newRole
          this.$store.dispatch('mainAppStore/updateWebUserItem', this.webUser).then(response => {
            console.log(response.data)
            this.$bvModal.hide('modal-edit-account')
            this.$swal({
              title: 'Succes',
              html: `${response.data.firstName} est modifier avec succes`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            })
          })
            .catch(err => {
              this.$swal({
                title: 'Erreur',
                html: err.response.data.message,
                icon: 'error',
                timer: 3000,
                showConfirmButton: false,
              })
              console.error(err.response.headers)
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
